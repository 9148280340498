import request from '@/utils/request'

/**
 * 获取物流公司列表
 * @param params
 */
export function getPaymentList(params) {
  return request({
    url: 'seller/shops/logi-companies',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 开启物流公司
 * @param logi_id
 */
export function openSwitch(logi_id) {
  return request({
    url: `seller/shops/logi-companies/${logi_id}`,
    method: 'post'
  })
}

/**
 * 关闭物流公司
 * @param params
 */

export function closeSwitch(logi_id) {
  return request({
    url: `seller/shops/logi-companies/${logi_id}`,
    method: 'delete'

  })
}

/**
 * 查询退货地址
 * @param params
 */

export function getBackAdress() {
  return request({
    url: "seller/shops/refund/address/getDetail",
    method: 'get'

  })
}

/**
 * 设置退货地址
 * @param params
 */

export function setBackAdress(id, params) {
  return request({
    url: `seller/shops/refund/address/${id}`,
    method: 'put',
    data: params
  })
}

/**
 * 修改密码
 * @param params
 */

export function changePwd(params) {
  return request({
    url: `/seller/shops/security/password`,
    method: 'put',
    data: params
  })
}

/**
 * 修改绑定的手机号
 * @param params
 */

export function changeMobile(mobile, params) {
  console.log(params, "params")
  return request({
    url: `seller/shops/security/exchange-bind/${mobile}`,
    method: 'put',
    params
  })
}

/**
 * 发送手机验证码
 * @param params
 */

export function sendMobile(mobile, params) {
  return request({
    url: `seller/shops/security/bind/send/${mobile}`,
    method: 'post',
    params

  })
}


/**
 * 获得个人信息
 * @param params
 */

export function getUserInfo() {
  return request({
    url: "seller/members/get-info",
    method: 'get'
  })
}

/**
 * 保存个人信息
 * @param params
 */

export function saveUserInfo(params) {
  return request({
    url: "seller/members/edit-info",
    method: 'post',
    data: params
  })
}

/**
 * 保存头像
 * @param params
 */

export function saveUserImg(params) {
  return request({
    url: "seller/members/edit-img",
    method: 'post',
    data: params
  })
}

/**
 * 获取店铺信息
 */

export function getShopInfo() {
  return request({
    url: '/seller/shops',
    method: 'get',
  })
}

/**
 * 获取验证码
 * @params params
 */

export function getSendSmsCode(params) {
  return request({
    url: '/seller/shops/sendEditLinkSmsCode',
    method: 'post',
    data: params
  })
}

/**
 * 修改联系人信息
 * @params params
 */

export function updateContactsInfo(params) {
  return request({
    url: '/seller/shops/editLink',
    method: 'put',
    data: params
  })
}
/**
 * 自提点列表查询
 */
export function pickupStationList (params) {
  return request({
    url: 'seller/shops/pickupStationList',
    method: 'get',
    params
  })
}
/**
 * 自提点详情查询
 */
export function getPickupStation (id) {
  return request({
    url: `/seller/shops/PickupStation/${id}`,
    method: 'get'
  })
}
/**
 * 添加自提点
 *  @params params
 */

export function addPickupStation(params) {
  return request({
    url: '/seller/shops/addPickupStation',
    method: 'post',
    headers: {
      'Content-Type':"application/json"
    },
    data:params
  })
}
/**
 * 修改自提点
 *  @params params
 */

export function editPickupStation(params) {
  return request({
    url: `/seller/shops/editPickupStation/${params.id}`,
    method: 'post',
    headers: {
      'Content-Type':"application/json"
    },
    data:params
  })
}
/**
 * 自提点启用禁用
 *  @params params
 */

export function editPickupState(data,id) {
  return request({
    url: `/seller/shops/editPickupState/${id}`,
    method: 'put',
    headers: {
      'Content-Type':"application/json"
    },
    params:data
  })
}
/**
 * 删除自提点
 *  @params params
 */

export function deletePickupStation(id) {
  return request({
    url: `/seller/shops/deletePickupStation/${id}`,
    method: 'delete'
  })
  
}
/**
 * 促销活动列表查询
 */
export function pieceActivityList (params) {
  return request({
    url: 'seller/promotion/activity/list',
    method: 'get',
    params
  })
}
/**
 * 添加促销活动
 *  @params params
 */

export function addPieceActivity(params) {
  return request({
    url: '/seller/promotion/activity',
    method: 'post',
    headers: {
      'Content-Type':"application/json"
    },
    data:params
  })
}
/**
 * 修改促销活动
 *  @params params
 */

export function editPieceActivity(params) {
  return request({
    url: `/seller/promotion/activity`,
    method: 'put',
    headers: {
      'Content-Type':"application/json"
    },
    data:params
  })
}
/**
 * 删除促销活动
 *  @params params
 */

export function deletePieceActivity(id) {
  return request({
    url: `/seller/promotion/activity/${id}`,
    method: 'delete'
  })
}
/**
 * 促销活动详情查询
 */
export function getPieceActivity (id) {
  return request({
    url: `/seller/promotion/activity/${id}`,
    method: 'get'
  })
}
/**
 * 更新单个促销活动状态
 *  @params params
 */
export function updatePieceActivity(params) {
  return request({
    url: `/seller/promotion/activity/updateStatus`,
    method: 'put',
    data:params
  })
}